import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageUvod from '../common/src/assets/image/charity/specter/kapitola_uvod_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Úvod | Ako duch komunizmu vládne nášmu svetu"
           description="Úvod"
           image={imageUvod}/>
      <h1>Úvod</h1>
      <p>Zrútenie komunistických režimov v Sovietskom zväze a vo východnej Európe znamenalo koniec polstoročia
        dlhej studenej vojny medzi kapitalistickým táborom na Západe a komunistickým táborom na Východe. Mnohí
        ľudia boli preto optimistickí a verili, že komunizmus sa stal históriou, ktorá pominula.</p>
      <p>Avšak smutnou pravdou je, že premenená komunistická ideológia namiesto toho pretrvala a pevne sa
        zakorenila po celom svete. Existujú režimy, ktoré sú neskrývane komunistické, ako Čína, Severná Kórea,
        Kuba a&nbsp;Vietnam. V krajinách východnej Európy má komunistická ideológia a&nbsp;jej zvyky stále
        významný vplyv a&nbsp;v krajinách afrického a juhoamerického kontinentu sa socializmus praktizuje pod
        vlajkou demokracie a republikanizmu. Potom sú tu národy Európy a Severnej Ameriky, ktoré sa stali
        hostiteľmi komunistických vplyvov bez toho, aby si to ľudia vôbec uvedomovali.</p>
      <p>Komunizmus plodí vojnu, hladomor, vraždenie a tyraniu. Tieto javy sú samé osebe desivé, ale škoda
        spôsobená komunizmom dosahuje ešte ďaleko väčších rozmerov. Mnohým ľuďom je čím ďalej jasnejšie, že na
        rozdiel od akéhokoľvek iného systému v histórii, to, čomu komunizmus vyhlasuje vojnu, je ľudstvo samotné –
        vrátane ľudských hodnôt a ľudskej dôstojnosti.</p>
      <p>Potom čo komunizmus zriadil masívne diktatúry v Sovietskom zväze a Číne, začal vládnuť miliardám ľudí
        a&nbsp;za menej než storočie zavinil viac než 100 miliónov neprirodzených úmrtí. V&nbsp;jeho pokuse
        o&nbsp;svetovú revolúciu komunistický blok priviedol svet na pokraj jadrovej vojny. Medzičasom jeho
        ľavicoví myslitelia a&nbsp;aktivisti propagovali úmyselné a&nbsp;rozsiahle ničenie rodiny, sociálneho
        poriadku a&nbsp;tradičnej morálky.</p>
      <p>Aká je teda povaha komunizmu? Čo je jeho cieľom? Prečo berie ľudstvo ako svojho nepriateľa? A ako mu
        môžeme uniknúť?</p>
      <h2>1. Komunizmus: Diabol, ktorý je odhodlaný zničiť ľudstvo</h2>
      <p><em>Komunistický manifest</em> začína vetou: „Po Európe straší duch – duch komunizmu.” Použitie termínu
        „duch komunizmu” nebolo iba rozmarom Karla Marxa. Ako tvrdíme v tejto knihe, komunizmus nemá byť chápaný
        ako ideologické hnutie, politická doktrína, ani ako nevydarený pokus o nájdenie nového spôsobu spravovania
        ľudských záležitostí. Namiesto toho by mal byť chápaný ako diabol – zlý duch ukovaný z nenávisti,
        degenerácie a ďalších živelných síl vo vesmíre.</p>
      <p>Po studenej vojne jed komunizmu nielenže naďalej poškodzoval bývalé komunistické krajiny, ale sa tiež
        rozšíril po celom svete. Odvtedy ideologická infiltrácia komunizmom umožnila duchovi komunizmu
        ovplyvňovanie ľudskej spoločnosti v globálnom meradle. Pod vplyvom komunistických myšlienok mnohí ľudia
        stratili schopnosť rozpoznať, čo je správne a nesprávne, rozlíšiť dobro od zla. Toto diablovo sprisahanie
        bolo takmer dokonané.</p>
      <h2>2. Diablove spôsoby a&nbsp;prostriedky</h2>
      <p>Človek bol stvorený Bohom a božský súcit človeka dlho chránil. Diabol to vie, a tak sa pustil do
        narušovania tohto spojenia, aby skazil človeka tak, že sa už o neho Boh nebude viac starať. Diablov
        prístup je podkopať kultúru, ktorú dali ľuďom Nebesá, a pokaziť ľudskú morálku, aby tak zdeformoval
        človeka a spôsobil, že nebude hodný spásy.</p>
      <p>Dobro aj zlo, božské aj diabolské, je v srdci každého človeka. Život môže poklesnúť do morálneho úpadku,
        alebo sa povzniesť kultiváciou morálky. Tí, ktorí veria v Boha, vedia, že ak sa budú usilovať o morálne
        správanie a myslenie, môžu byť ich spravodlivé myšlienky posilnené Bohom a Boh potom dovolí, aby sa stali
        zázraky. Boh tiež človeku pomôže, aby jeho morálna úroveň stúpala a on sa mohol stať ušľachtilejšou
        bytosťou, a nakoniec mu umožní vrátiť sa do neba.</p>
      <p>Osoba nízkych mravov je naproti tomu naplnená sebectvom: túžbou, chamtivosťou, ignoranciou a spupnosťou.
        Kým Nebesá takéto myšlienky a činy nikdy neuznajú, diabol ich bude zosilňovať. Tým, že v človeku posilní
        sebeckosť a zlomyseľnosť, bude ho manipulovať k páchaniu zla, čím si bude človek vytvárať karmu a
        prehlbovať svoj morálny úpadok, až ho nakoniec bude očakávať iba peklo.</p>
      <p>Pokiaľ dôjde k celkovému poklesu morálnych hodnôt ľudskej spoločnosti, diabol bude tieto trendy
        urýchľovať s cieľom, aby vytvoril ľuďom ešte viac hriechov, viac karmy a&nbsp;aby v konečnom dôsledku
        ľudstvo úplne zničil. Nepokoje, ktoré začali v 18. storočí v Európe, a s nimi súvisiaci morálny úpadok,
        dali diablovi príležitosť. Krôčik po krôčiku sa pustil do rozvracania kritérií pre rozlíšenie dobra a zla.
        Propagoval ateizmus, materializmus, darwinizmus a filozofiu boja.</p>
      <p>Diabol si vybral Marxa za svojho vyslanca medzi ľuďmi. V komunistickom manifeste uverejnenom v roku 1848
        Marx obhajoval násilné zničenie súkromného podnikania, sociálnych tried, národov, náboženstiev a rodiny.
        Parížska komúna z roku 1871, ktorá bola mimoriadne násilná a deštruktívna, bola prvým diablovým pokusom o
        uchopenie moci.</p>
      <p>Marxovi nasledovníci tvrdia, že ústrednou otázkou marxistickej politickej vedy je politická moc. To je
        pravda len čiastočne. Keď jasne vidíme konečné ciele komunizmu, uvedomíme si, že politická moc je pre
        komunistický projekt dôležitá, ale zároveň na nej až tak nezáleží. Politická moc umožňuje využívať rýchle
        prostriedky k rozsiahlemu skazeniu ľudstva. S pákami moci môžu komunisti presadzovať svoju ideológiu
        násilím, a tak môžu odstrániť tradičnú kultúru v priebehu niekoľkých desaťročí či aj skôr. Napriek tomu na
        moci tiež nezáleží, pretože aj bez štátneho aparátu má diabol ďalšie prostriedky na využívanie slabostí a
        nedostatkov človeka: aj keď komunisti nemajú politickú moc, jeho vyslanci na zemi môžu podvádzať,
        privlastňovať si, nútiť a&nbsp;miasť spoločnosť, podkopávajúc poriadok a vytvárajúc nepokoje, aby
        rozvrátili tradičné hodnoty. Komunizmus používa taktiku „rozdeľuj a&nbsp;panuj“ s cieľom získania
        globálnej kontroly.</p>
      <h2>3. Komunizmus je ideológiou diabla</h2>
      <p>Nebesá pre ľudskú spoločnosť ustanovili bohatú kultúru založenú na univerzálnych hodnotách. Tak pre ľudí
        pripravili cestu, aby sa raz mohli vrátiť do neba. Komunizmus a tradičná kultúra, ktorá má božský pôvod,
        sú nezlučiteľné.</p>
      <p>Jadrom kultúry šírenej zlým duchom komunizmu je ateizmus a materializmus: spojením prvkov vplyvu nemeckej
        filozofie, francúzskej sociálnej revolúcie a britskej politickej ekonómie vzniklo svetské náboženstvo,
        ktoré malo nahradiť pozíciu, ktorá v minulosti patrila Nebesám a pravoverným vieram.</p>
      <p>Komunizmus obracia svet na svoju vlastnú vieru a privádza všetky aspekty spoločenského života pod svoju
        kontrolu a vplyv. Diabol vstúpil do ľudskej mysle a prinútil človeka, aby sa vzbúril proti nebesiam a
        odvrhol tradície. Takto démon smeruje človeka k jeho vlastnej záhube.</p>
      <p>Diabol si vybral Marxa a ďalších ako svojich vykonávateľov, aby sa postavili proti princípom a zničili
        princípy, ktoré Nebesia stanovili pre ľudskú spoločnosť. Diabol podporuje triedny boj a zrušenie
        zavedených spoločenských štruktúr. Na Východe začal násilnú revolúciu a ustanovil totalitný štát, ktorý
        zjednotil politiku a svetské náboženstvo. Na Západe zavádza progresívny, nenásilný komunizmus
        prostredníctvom vysokej úrovne zdanenia a prerozdeľovania bohatstva. V celosvetovom meradle sa snaží
        rozšíriť komunistickú ideológiu do všetkých politických systémov s cieľom podkopať národné štáty a
        vytvoriť globálny vládnuci orgán. Toto je „raj na zemi” sľubovaný komunizmom, takzvaná kolektívna
        spoločnosť bez tried, národov alebo vlád, založená na princípoch „každý podľa svojich schopností, každému
        podľa jeho potrieb”.</p>
      <p>Komunizmus používa svoj program vytvárania „raja” na zemi, aby podporil ateistické poňatie „spoločenského
        pokroku”. Využíva materializmus k oslabeniu duchovných snáh ľudstva, aby komunistickej ideológii umožnil
        rozšíriť sa do každej sféry, nielen do spoločenských vied a filozofie, ale aj do prírodných vied
        a&nbsp;náboženského učenia. Komunizmus podobne ako rakovina metastázuje a ako sa rozširuje, odstraňuje iné
        viery, vrátane viery v Nebesá. Postupne ničí národnú zvrchovanosť a&nbsp;identitu, ako aj morálne a
        kultúrne tradície ľudstva, čím človeka vedie k zničeniu. V Komunistickom manifeste Marx vyhlásil:
        „Komunistická revolúcia je najradikálnejším zúčtovaním s tradičnými vlastníckymi vzťahmi; nie div, že sa v
        priebehu jej rozvoja najradikálnejšie zúčtuje s tradičnými myšlienkami.” Sám Marx tak presne zhrnul prax
        komunizmu prebiehajúcu v posledných dvoch storočiach.</p>
      <p>Boh je zdrojom morálneho poriadku a Božia morálka je večná a nemenná. Morálne štandardy nikdy nebudú
        určované človekom a ani nemôžu byť ľudskými silami zmenené. Komunizmus sa pokúša odsúdiť morálku
        spoločnosti k zániku a stvoriť komunistického „nového človeka”, ktorý ustanoví novú morálku. Kým popiera
        skutočnú morálku, snaží sa pomocou negatívnych metód postupne odstraňovať z ľudských tradícií pozitívne
        prvky, s cieľom rozšíriť negatívne prvky po celom svete.</p>
      <p>Tradičné zákony boli vytvorené na základe morálnych hodnôt a sú určené na udržiavanie morálky. Komunizmus
        sa snaží oddeliť morálku od zákona a potom ničí morálku tým, že vytvára škodlivé zákony a zlomyseľne
        vykladá zákony tradičné.</p>
      <p>Boh v tradičnej kultúre vyzýva človeka, aby bol láskavý; komunizmus podnecuje triedny boj a obhajuje
        násilie a zabíjanie.</p>
      <p>Boh ustanovil rodinu ako základnú jednotku spoločnosti; komunizmus verí, že rodina je prejavom súkromného
        vlastníctva a kapitalistického systému, a&nbsp;chce ju odstrániť.</p>
      <p>Boh dáva človeku slobodu získať bohatstvo a právo zlepšiť svoj životný údel; komunizmus sa snaží prevziať
        úplnú kontrolu nad ekonomickým životom tým, že zruší súkromné vlastníctvo, vyvlastní majetok ľudí, zvýši
        dane a monopolizuje úvery a kapitál.</p>
      <p>Boh ustanovil formy, ktoré by mali morálka, vláda, právo, spoločnosť a kultúra mať; komunizmus sa snaží o
        násilné zvrhnutie celého doterajšieho spoločenského poriadku.</p>
      <p>Boh odovzdal človeku jedinečnú formu tradičného umenia, ktorá mala slúžiť ako prostriedok na odovzdávanie
        jeho obrazu. Tradičné umenie pripomína ľudstvu krásu nebies, posilňuje vieru, povznáša morálku a posilňuje
        cnosť. Komunizmus na druhej strane vedie človeka k uctievaniu pokrivených moderných výtvorov
        –&nbsp; umeleckej tvorby, ktorá potláča našu božskú povahu a dáva priechod démonickému sklonu ku chaosu a
        neporiadku, manipuluje umelecký svet rozširovaním nízkych, škaredých, pokrivených, zlých a dekadentných
        myšlienok.</p>
      <p>Boh si želá, aby bol človek skromný a naplnený úctou a obdivom k božskému stvoreniu. Komunizmus
        povzbudzuje démonickú stránku a aroganciu vo vnútri človeka a dodáva mu odvahu k vzbure proti Bohu.
        Zosilňuje zlú stránku, ktorá je súčasťou ľudskej povahy, a pod myšlienkou „slobody” povzbudzuje človeka k
        správaniu, ktoré nerešpektuje žiadne morálne hranice, k správaniu bez pocitu zodpovednosti a povinnosti.
        Využíva slogan „rovnosti”, aby vyvolával závisť a márnivosť, keďže človek je pokúšaný slávou a hmotnými
        záujmami.</p>
      <p>Po druhej svetovej vojne komunisti rozšírili svoje vojenské a ekonomické impérium, pričom komunistický
        blok a slobodný svet po celé desaťročia navzájom súperili. Komunistická doktrína sa v krajinách pod
        komunistickou nadvládou stala sekulárnym náboženstvom a nespochybniteľnou pravdou zapísanou v učebniciach.
        Avšak inde sa komunizmus zakorenil pod odlišným pláštikom.</p>
      <h2>4. Metafyzické pochopenie diabla</h2>
      <p>Predstava diabla, o&nbsp;ktorom hovoríme v&nbsp;tomto texte, je predstava nadprirodzenej moci. Aby sme
        porozumeli chaosu, ktorý diabol vo svete rozsieva, musíme najprv plne pochopiť pravú podstatu
        komunizmu.</p>
      <p>Jednoducho povedané, duch komunizmu je tvorený nenávisťou. Čerpá svoju energiu z nenávisti, ktorá sa
        dvíha v ľudskom srdci.</p>
      <p>Duch komunizmu je spojený so Satanom; niekedy sú tieto dve entity nerozlíšiteľné, a preto o nich nebudeme
        uvažovať ako o dvoch samostatných bytostiach.</p>
      <p>Diablove usporiadania sa prejavujú na Východe ako aj na Západe, v každej profesii a vo všetkých
        oblastiach života. Niekedy je jeho sila rozdelená, niekedy je koncentrovaná; niekedy použije jednu
        taktiku, inokedy druhú. Pri svojom konaní nenasleduje nejaký jednoduchý vzor. Diabol je iniciátorom
        neobmedzenej vojny proti ľudstvu. Vojny, ktorá premenila náboženstvo, rodinu, politiku, ekonomiku,
        financie, vojenské záležitosti, vzdelanie, akademickú obec, umenie, médiá, zábavu, ľudovú kultúru,
        sociálne záležitosti a medzinárodné vzťahy na bojové polia.</p>
      <p>Temná energia diabla sa môže šíriť z jednej sféry, skupiny alebo hnutia do ďalších. Napríklad potom, čo
        na Západe v 70. rokoch 20. storočia zoslablo hnutie proti vietnamskej vojne, diabol začal manipulovať
        vzdorujúcich adolescentov, aby nasmerovali svoju energiu do podpory feminizmu, environmentalizmu a
        legalizácie homosexuality. Diabol potom tieto snahy využil k podkopaniu západnej civilizácie zvnútra.</p>
      <p>Diabol môže premeniť ľudí, ktorí nemajú dobré úmysly, na svojich reprezentantov v ľudskom svete. Pod jeho
        vplyvom budú používať klamlivé apely na ľudskosť a&nbsp;ďalšie podvodné prostriedky, aby pritiahli
        súcitných a nevinných ľudí na svoju stranu. Keďže ich zmysel pre spravodlivosť a&nbsp;morálku bude unesený
        komunizmom, stanú sa jednými z&nbsp;najvernejších obhajcov diabla.</p>
      <p>Diablovi zástupcovia – z ktorých väčšina si ani neuvedomuje, akú úlohu zohráva – sú v celej spoločnosti,
        od spoločenských elít, cez strednú až po nižšiu triedu. Jeho aktivity sa teda niekedy prejavujú ako
        revolúcia vedená zdola nahor, niekedy ako sprisahania smerujúce zhora nadol, a niekedy ako reformy
        organizované zo stredu.</p>
      <p>Diabol môže meniť svoju formu a existovať na niekoľkých miestach naraz. K vykonávaniu svojich vecí
        využíva nízke bytostí a duchov existujúcich v iných dimenziách. Tieto bytosti sa živia negatívnymi
        energiami v človeku, ako nenávisť, strach, zúfalstvo, arogancia, vzdorovitosť, závisť, žiadostivosť, hnev,
        zúrivosť, lenivosť a ďalšie. Nástroje, ktoré diabol používa, sú pornografia a drogová závislosť.</p>
      <p>Diabol je tajnostkársky a ľstivý. Využíva ľudskú chamtivosť, hriešnosť a temnotu, aby dosiahol svoje
        ciele, a ak sa myšlienky človeka zhodujú s týmito vlastnosťami, diabol môže takéhoto človeka ovládať.
        Často si ľudia myslia, že konajú podľa vlastných myšlienok, ale neuvedomujú si, že sú manipulovaní.</p>
      <h2>5. Mnoho tvárí diabla</h2>
      <p>Tak ako diabol má veľa mien, aj komunizmus sa prejavuje mnohými spôsobmi. Démon zastáva protichodné
        pozície na oklamanie ľudí: totalitný režim alebo demokraciu; plánovanú alebo trhovú ekonomiku; kontrolu
        tlače alebo absolútnu slobodu slova; opozíciu voči homosexualite v niektorých krajinách alebo legalizáciu
        homosexuality v iných krajinách; bezohľadné ničenie životného prostredia, či naopak hlasné domáhanie sa
        jeho ochrany a tak ďalej.</p>
      <p>Môže obhajovať násilnú revolúciu, alebo podporovať pokojnú transformáciu. Môže sa prejavovať ako
        politický a ekonomický systém, alebo ako ideologický trend v umení a kultúre. Môže mať formu číreho
        idealizmu alebo chladnokrvných intríg. Komunistické totalitné režimy sú len jedným prejavom tohto démona.
        Marxizmus-leninizmus a maoizmus sú len jedným prejavom diablovho klamu.</p>
      <p>Od utopického socializmu v 18. storočí sa vo svete objavilo mnoho rozličných ideologických prúdov:
        vedecký socializmus, fabiánsky socializmus, syndikalizmus, kresťanský socializmus, demokratický
        socializmus, humanitarizmus, eko-socializmus, sociálny kapitalizmus, marxizmus-leninizmus a maoizmus.
        Tieto ideológie možno rozdeliť do dvoch širokých a&nbsp;niekedy prekrývajúcich sa typov: násilný
        komunizmus a nenásilný komunizmus. Infiltrácie a postupná erózia existujúceho stavu v spoločnosti sú
        hlavnými taktikami, ktoré prijali nenásilné formy komunizmu.</p>
      <p>Jedným zo spôsobov, ktorým diabol klame, je to, že rozohral svoje plány v opačných táboroch na Východe a
        Západe súčasne. Zatiaľ čo rozsiahlo okupoval Východ, dal si novú masku a vkradol sa aj na Západ. Britská
        Fabiánska spoločnosť, nemecká Sociálno-demokratická strana, Druhá internacionála vo Francúzsku,
        Socialistická strana v Spojených štátoch a mnoho ďalších socialistických strán a organizácií rozšírili
        semienka deštrukcie do západnej Európy a Severnej Ameriky.</p>
      <p>Počas studenej vojny si niektorí ľudia na Západe uvedomili, aké majú šťastie, že nemusia zažívať
        vraždenie, koncentračné tábory, hladovanie a čistky, ako tí v Sovietskom zväze a&nbsp;v&nbsp;Číne, a že
        žijú v luxuse a slobode. Niektorí socialisti z humanitárneho hľadiska verejne odsúdili násilie páchané v
        Sovietskom zväze, čo mnohých ľudí viedlo k tomu, že sa v blízkosti socialistov prestali mať na pozore.</p>
      <p>Démon komunizmu nosí na Západe mnoho rozličných masiek a pôsobí pod mnohými zástavami, takže je takmer
        nemožné sa pred ním ochrániť. Nižšie uvádzame školy a hnutia, ktoré boli buď odvodené od komunizmu, alebo
        boli komunizmom použité na dosiahnutie jeho cieľov: liberalizmus, progresivizmus, Frankfurtská škola,
        neomarxizmus, kritická teória, alternatívna kultúra 60. rokov 20 storočia, protivojnové hnutie, sexuálna
        sloboda, legalizácia homosexuality, feminizmus, environmentalizmus, sociálna spravodlivosť, politická
        korektnosť, keynesiánska ekonomika, avantgardné umenie a multikulturalizmus.</p>
      <h2>6. Socializmus ako úvodné štádium komunizmu</h2>
      <p>Na Západe sa mnoho ľudí pozerá na socializmus a komunizmus ako na dve oddelené veci, čo dáva socializmu
        živnú pôdu, aby mohol prekvitať. V skutočnosti je socializmus podľa teórie marxizmu-leninizmu jednoducho
        len úvodnou fázou komunizmu.</p>
      <p>V roku 1875 Marx vo svojej <em>Kritike gothajského programu</em> predložil myšlienku, že existuje úvodná
        fáza komunizmu a pokročilá,&nbsp; „vyššia” fáza komunizmu. V reakcii na zmeny v medzinárodnej situácii v
        tej dobe Friedrich Engels vo svojich neskorších rokoch tiež navrhoval myšlienku „demokratického
        socializmu”, v ktorom sa politická moc získavala pomocou hlasovania. Demokratický socializmus bol prijatý
        vodcami&nbsp; sociálnodemokratických strán a teoretikmi Druhej internacionály a dnes sa stal programom
        ľavicových strán v mnohých kapitalistických krajinách po celom svete. Neskôr Lenin jasne definoval
        socializmus a komunizmus: socializmus považoval za úvodné štádium komunizmu a komunizmus sa mal budovať na
        základoch socializmu. Štátne vlastníctvo a plánovaná ekonomika socializmu sú súčasťou začiatočných príprav
        na komunizmus.</p>
      <p>Socializmus bol vždy súčasťou marxizmu a medzinárodného komunistického hnutia. V súčasnosti, hoci sa zdá,
        že vetvy socializmu a ľavicové doktríny, ktoré sú populárne na Západe,&nbsp; na povrchu nemajú s
        komunizmom nič spoločné, sú to v skutočnosti len nenásilné formy tej istej základnej ideológie. Namiesto
        násilnej revolúcie tieto ľavicové strany v západných krajinách získavajú moc na základe voličských hlasov.
        Namiesto priameho štátneho vlastníctva, ktoré funguje v komunistických režimoch, sa v západných krajinách
        používa metóda vysokého zdanenia, ktorá slúži na rovnaký cieľ. Namiesto plánovanej ekonomiky fungujú na
        Západe systémy s vysokou mierou sociálneho zabezpečenia, ktoré sú len alternatívnymi formami s tým istým
        cieľom. Vytvorenie sociálneho štátu je dôležitou súčasťou realizácie socializmu v západných krajinách.</p>
      <p>Aby sme teda porozumeli nebezpečenstvu komunizmu či socializmu, nemôžeme sa zameriavať len na násilie a
        vraždenie páchané režimami, ktoré tieto ideológie zastávajú. Totalitný komunizmus a zdanlivo nenásilné
        formy socializmu idú ruka v ruke, pretože komunizmus sa vyvíja zo svojej úvodnej fázy a rovnako ako živý
        organizmus rastie pozvoľna. Ak by sa slobodná krajina stala cez noc totalitným režimom, drastický rozdiel
        medzi propagandou a realitou by väčšinu ľudí šokoval. Mnoho z nich by proti tomu bojovalo, alebo
        prinajmenšom sa pasívne stavalo na odpor. Režim by sa pravdepodobne musel uchýliť k masovému vraždeniu,
        aby tento odpor odstránil. To je jeden z hlavných dôvodov, prečo sa Sovietsky zväz a Čínska ľudová
        republika uchýlili k masovému zabíjaniu svojich vlastných občanov v čase mieru.</p>
      <p>Na rozdiel od totalitných režimov, socializmus v demokratických krajinách pomaly uberá ľuďom z ich slobôd
        prostredníctvom legislatívy, bez toho aby si to vôbec uvedomovali – rovnako ako v tej metafore o pomaly
        variacej sa žabe. Proces ustanovovania socialistického systému trvá desiatky rokov, či celé generácie, čo
        ľudí postupne privedie do stavu apatie, ľahostajnosti voči socializmu, na ktorý si zvyknú, čo len
        podporuje klam. Podstata a konečný cieľ tohto typu postupného socializmu sa vo svojej podstate nijako
        nelíši od násilnej formy.</p>
      <p>Niektoré ľavicovo-orientované štáty na Západe dnes používajú myšlienku „spoločného dobra” s cieľom
        presvedčiť svojich obyvateľov, aby sa zriekli svojich osobných slobôd. Občania v týchto krajinách si
        zachovávajú určité politické slobody len preto, že socializmus sa tam ešte nestal silným politickým
        systémom. Socializmus však nie je statickým konceptom. Socialistické krajiny si za svoj prvotný cieľ
        stanovili rovnosť výsledku, a tak v mene pokroku celkom iste oberú ľudí o ich slobodu. Socializmus
        nevyhnutne prechádza do komunizmu a spolu s tým sú ľudia neustále ochudobňovaní o svoje práva. To, čo
        zostane, je tyranský a&nbsp;autoritatívny režim.</p>
      <p>Socializmus využíva myšlienku garancie rovnosti výsledku pomocou legislatívy, zatiaľ čo v skutočnosti
        podporuje úpadok morálnych hodnôt a zbavuje ľudí slobody smerovať k dobru. Za normálnych podmienok majú
        rôzni ľudia prirodzene rôzne náboženské presvedčenie, morálne štandardy, kultúrnu gramotnosť, úroveň
        vzdelania, inteligenciu, statočnosť, usilovnosť, zmysel pre zodpovednosť, mieru agresivity, schopnosť
        inovácie, podnikania a tak ďalej. Je samozrejme nemožné prinútiť ľudí k rovnosti tým, že náhle pozdvihneme
        tých, ktorí sú na nižších úrovniach – a tak namiesto toho socializmus umelo obmedzuje tých, ktorí sú na
        úrovniach vyšších. Zvlášť pokiaľ ide o morálne hodnoty, socializmus na Západe používa zámienky ako
        „antidiskriminácia”, „hodnotová neutralita” alebo „politická korektnosť”, aby útočil na schopnosť
        základného morálneho rozlišovania ľudí.&nbsp; To sa rovná pokusu o odstránenie morálky ako takej. Ruka v
        ruke s tým prichádza legalizácia a normalizácia všetkých druhov anti-teizmu a rúhavých slov v reči,
        sexuálna perverznosť, démonické umenie, pornografia, hazardné hry a užívanie drog. Výsledkom je akási
        prevrátená diskriminácia tých, ktorí veria v Boha a usilujú sa o morálne pozdvihnutie, s cieľom ich
        marginalizácie a následná snaha o ich úplné odstránenie.</p>
      <h2>7. Idealizácia komunizmu</h2>
      <p>Ešte aj dnes stále existuje veľa ľudí zo Západu, ktorí majú o komunizme idealistické predstavy, avšak
        nikdy nežili v komunistickej krajine a nezažívali v nej utrpenie, a preto nemajú predstavu, čo komunizmus
        v praxi skutočne znamená. Počas studenej vojny sa mnoho intelektuálov, umelcov, novinárov, politikov a
        mladých študentov zo slobodného sveta vydalo do Ruska, Číny alebo na Kubu ako turisti a cestovatelia. To,
        čo videli, alebo skôr čo im bolo umožnené vidieť, bolo úplne odlišné od skutočnej reality každodenného
        života ľudí v týchto krajinách.</p>
      <p>Komunistické krajiny sa zdokonalili v klamaní cudzincov: všetko, čo zahraniční návštevníci videli, bolo
        starostlivo vytvorené, aby to vyhovovalo ich vkusu, vrátane modelových dedín, tovární, škôl, nemocníc,
        centier starostlivosti o deti a väzníc. Recepční, s ktorými sa stretli, boli členovia komunistickej strany
        alebo takí, ktorí boli považovaní za politicky spoľahlivých. Prehliadky boli vopred pripravené a
        naskúšané. Návštevníkov privítali kvetinami, vínom, tancom a spevom, banketmi a usmievajúcimi sa malými
        deťmi a úradníkmi. Potom ich vzali medzi tvrdo pracujúci ľud, aby im ukázali, že môžu slobodne hovoriť ako
        rovnoprávni občania; a potom s nimi zavítali medzi usilovne študujúcich študentov a na krásne svadby.</p>
      <p>To, čo im však neukázali, boli zinscenované súdne procesy, hromadné rozsudky, skupinové lynčovania,
        bojové schôdze, únosy, vymývanie mozgov, samotky, tábory nútených prác, masakre, krádeže pôdy a majetku,
        hladomory, nedostatok verejných služieb, strata súkromia, odpočúvanie, policajné sledovania, špehujúci
        susedia a informátori na každom kroku, brutálne politické boje vo vedení strany a extravagantný prepych
        straníckej elity. Zvlášť im nebolo dovolené vidieť utrpenie obyčajných ľudí.</p>
      <p>Návštevníci si neuvedomili, že to, čo pre nich bolo zohrané ako ukážka štandardného života v
        komunistickej krajine, bolo falošné. Potom propagovali komunizmus na Západe prostredníctvom kníh, článkov,
        prejavov a mnohí z nich ani nevedeli, že boli oklamaní. Zopár ľudí „videlo v stavbe trhliny”, ale mnohí z
        nich spadli do inej pasce: videli sami seba ako „spolucestujúcich” a zaujali čínsky postoj „nesušiť
        špinavú bielizeň pred cudzími ľuďmi”. Zabíjanie, hladomor a represie v komunistických krajinách
        odôvodňovali ako súčasť ceny, ktorú treba zaplatiť počas prechodu spoločnosti ku komunizmu. Boli
        presvedčení, že aj keď je cesta ku komunizmu pokrivená, budúcnosť bude svetlá. Odmietli hovoriť pravdu,
        pretože by tým hanobili meno „socialistického projektu”. Nemali odvahu hovoriť pravdu a zvolili si hanebné
        mlčanie.</p>
      <p>Podľa komunistickej utópie sú všetci ľudia slobodní a rovnoprávni, nedochádza k útlaku ani
        vyvlastňovaniu, je tu materiálna hojnosť, každý dáva podľa svojich schopností a dostáva podľa svojich
        potrieb – nebo na zemi, kde má každý človek možnosť slobodne sa rozvíjať. Takáto ľudská spoločnosť je však
        jednoducho výplodom fantázie a obraz tejto fantázie použil diabol ako návnadu na oklamanie ľudí.</p>
      <p>V skutočnosti drží moc v rukách nepočetná elita. Skutočný komunizmus je totalitný aparát riadený malou
        skupinou, ktorá využíva mocenský monopol na potláčanie, zotročovanie a okrádanie väčšiny. V niektorých
        socialistických krajinách zatiaľ ešte k tomuto kroku nedošlo, a tak sa zdá, že sú ich režimy mierne. Keď
        budú priaznivé podmienky, všetko sa zmení a naivní prívrženci socialistickej utópie zistia, že na ľútosť
        je už príliš neskoro.</p>
      <h2>8. Ničenie kultúry a&nbsp;morálky</h2>
      <p>Diabol dosadil svojich zástupcov do všetkých oblastí a národov a viedol pomocou nich nevedomých a
        dôverčivých ľudí k tomu, aby urýchlili svoju cestu k vlastnej záhube.</p>
      <p>Komunizmus učí ľudí, aby sa postavili proti viere v Boha a zavrhli všetko božské. Iniciuje útoky na
        náboženstvo zvonku a zároveň manipuluje ľuďmi, aby poškodzovali náboženstvo zvnútra. Náboženstvá boli
        spolitizované, komercializované a premenené na zábavu. Mnohí morálne skazení kňazi falošne interpretujú
        náboženské texty, zavádzajú svojich nasledovníkov a zašli tak ďaleko, že sa dopúšťajú cudzoložstva s
        laickými členmi svojej cirkvi, a dokonca páchajú pedofíliu.</p>
      <p>Tento chaos spôsobil, že úprimní náboženskí veriaci sú zmätení a&nbsp;stratili nádej. Pred storočím bola
        neochvejná duchovná viera&nbsp; znakom morálky a slušnosti. Teraz sú náboženskí veriaci považovaní za
        hlúpych a poverčivých. Nechávajú si svoju vieru sami pre seba a dokonca ani medzi svojimi priateľmi o
        svojej viere nehovoria z obavy, že sa im budú posmievať.</p>
      <p>Ďalším dôležitým cieľom komunizmu je zničenie rodiny pomocou myšlienok o „rodovej rovnosti” a „zdieľania
        bohatstva a manželky”. Dvadsiate storočie zaznamenalo veľkú vlnu moderných feministických hnutí, ktoré
        podporovali sexuálnu slobodu, zmazanie rozdielov medzi pohlaviami, útočili na takzvaný „patriarchát” a
        oslabovali úlohu otca v rodine.</p>
      <p>Tieto hnutia zmenili definíciu manželstva, podporovali legalizáciu a legitimizáciu homosexuality,
        podporovali „právo” na rozvod a potrat a využívali politiku sociálnej podpory na to, aby efektívne
        podporovali a dotovali rodinu s jedným rodičom. To všetko viedlo k zrúteniu rodín, k rozširovaniu chudoby
        a rastu zločinnosti. Ide o jednu z najdesivejších zmien spoločnosti za posledných niekoľko desaťročí.</p>
      <p>Pokiaľ ide o oblasť politiky, zatiaľ čo komunistické režimy pokračovali so svojimi tvrdými diktatúrami,
        stranícka politika v slobodných spoločnostiach sa dostala do krízy. Komunizmus v snahe manipulovať veľkými
        politickými stranami využil medzery v právnych a politických systémoch demokratických národov. Pre volebné
        víťazstvo sa politici uchýlili ku špinavým trikom a dávali sľuby, ktoré nikdy nemohli splniť. V dôsledku
        vplyvu komunizmu sa politické strany po celom svete často pohybujú niekde na ľavej strane politického
        spektra, obhajujú vyššie dane, vyššie sociálne výdavky, veľký vládny aparát a intervencionizmus – a toto
        všetko sa snažia ukotviť v legislatíve. Správanie vlády hrá obrovskú úlohu vo formovaní spoločnosti a s
        ľavicovo orientovanou vládou prichádza tiež ľavicová ideológia, ktorá sa infiltruje do celej spoločnosti,
        indoktrinuje mládež, a tá potom volí ďalších ľavicových kandidátov.</p>
      <p>Akademická oblasť, ktorá má hrať úlohu nositeľa múdrosti a kultúry vekov, bola podkopaná tiež. V prvej
        polovici 20. storočia zosnoval komunistický duch systematické zničenie vzdelávacieho systému. Čína, známa
        svojou hlbokou starodávnou kultúrou, zažila hnutie za novú kultúru dokonca ešte pred založením
        komunistickej strany. To bola súčasť úsilia o oddelenie čínskeho ľudu od jeho vlastných tradícií. Keď sa
        komunisti chopili moci, znárodnili vzdelávací systém, naplnili učebnice ideológiou strany a premenili
        generáciu mladých Číňanov na zúrivé „vlčie mláďatá”, čo je čínsky termín používaný na tých, ktorí
        vyrastali v komunizme a boli indoktrinovaní k tomu, aby nenávideli a zabíjali triednych nepriateľov.</p>
      <p>Na Západe duch komunizmu pod zástavou „vedy a pokroku” odštartoval hnutie progresívneho
        vzdelávania,&nbsp; aby získal kontrolu nad filozofiou, psychológiou, pedagogikou a nakoniec nad celým
        vzdelávacím systémom, a tak indoktrinoval učiteľov a ľudí z oblasti vzdelávania. Stredoškolské vzdelanie
        prestalo pripúšťať ortodoxné myšlienky a tradičnú morálku. Akademické štandardy boli znížené, aby študenti
        boli menej sčítaní a menej gramotní, a v dôsledku toho menej schopní vytvoriť si vlastný úsudok a používať
        zdravý rozum. Študentom bol vštepovaný ateizmus, teória evolúcie, materializmus a filozofia boja.</p>
      <p>Po kontrakultúre 60. rokov sa obhajcovia politickej korektnosti stali myšlienkovou políciou, ktorá
        donútila učiteľov indoktrinovať študentov rôzne pokrútenými myšlienkami. Študenti teraz absolvujú školu
        bez silného morálneho kompasu, bez znalosti základov vlastnej kultúry, chýba im&nbsp; zdravý rozum a
        zmysel pre zodpovednosť. Sú ponechaní, aby slepo nasledovali dav, a&nbsp;nasledujú tak spoločenský úpadok
        nadol.</p>
      <p>V spoločnosti medzitým dochádza k zneužívaniu drog, zvyšovaniu miery kriminality, mediálna sféra je plná
        sexu a násilia, umelecký svet považuje ohyzdné veci za krásne a medzi ľuďmi pôsobia rôzne nebezpečné sekty
        a okultné skupiny. Mladí ľudia slepo uctievajú filmové a televízne hviezdy, strácajú čas pri online hrách
        a na sociálnych sieťach, a v dôsledku toho sú skľúčení a demoralizovaní. Ľudia sa zúfalo obávajú
        o&nbsp;bezpečnosť sveta a&nbsp;o&nbsp;budúcnosť, zoči-voči nezmyselnému násiliu a terorizmu.</p>
      <h2>9. Návrat k Bohu a&nbsp;k&nbsp;tradíciám</h2>
      <p>Ľudskú civilizáciu odovzdali človeku nebesá. Čínska civilizácia zažila prosperitu dynastií Chan a Tchang
        a západná civilizácia dosiahla svoj vrchol v období renesancie. Ak ľudské bytosti dokážu zachovať
        civilizáciu, ktorú im nebesá dali, potom si ľudia budú schopní udržať spojenie s nebesami a budú môcť
        porozumieť Zákonu, ktorému ich bude Boh učiť, keď sa vráti do ľudskej ríše. Ak si ľudia zničia svoju
        kultúru a tradície a morálka spoločnosti sa zrúti, ľudia nebudú chápať božie učenie, pretože ich karma a
        hriechy budú príliš veľké a ich myslenie sa príliš vzdiali od božích pokynov. To je pre ľudstvo
        nebezpečné.</p>
      <p>Žijeme v ére zúfalstva, ale aj nádeje. Neveriaci si v živote užívajú zmyselné potešenia. Veriaci čakajú
        na návrat Boha, v zmätku a vnútorne znepokojení.</p>
      <p>Komunizmus je pre ľudstvo pohromou. Jeho cieľom je ľudstvo zničiť a jeho usporiadania sú dôkladné a
        presné. Toto sprisahanie bolo tak úspešné, že bolo takmer dokonané, a diabol teraz riadi náš svet.</p>
      <p>Dávna múdrosť ľudstva hovorí, že jedna spravodlivá myšlienka premôže sto ziel, a keď&nbsp; v človeku
        vystúpi jeho Budhovská povaha, zatrasie svetom desiatich smerov. Diabol sa zdá byť mocný, ale pred Bohom
        nie je ničím. Ak si ľudia dokážu zachovať úprimnosť, láskavosť, súcit, toleranciu a trpezlivosť, nebesia
        ich budú ochraňovať a diabol nad nimi nebude mať žiadnu moc.</p>
      <p>Stvoriteľov súcit je nekonečný a každý život má šancu uniknúť katastrofe. Ak bude ľudstvo schopné obnoviť
        svoje tradície, pozdvihnúť svoju morálku a uslyšať súcitnú výzvu Stvoriteľa a Nebeského zákona, ktorý
        poskytuje spásu, človek bude môcť uniknúť diablovmu pokusu o jeho zničenie, vydá sa na cestu k spáse a
        vykročí smerom k budúcnosti.</p>
      <Link to={`/kapitola_01`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


